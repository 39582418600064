import React, { useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { DocumentData, serverTimestamp, Timestamp } from 'firebase/firestore';
import { auth } from '../../utils/firebase';

import { MessageInput, MessageList, MessageSeparator, } from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { Avatar, Box, Button, Checkbox, Divider, Grid, SvgIcon, Tab, Tabs,
         Typography } from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import WidgetLoading from '../../assets/animations/widgetLoading.json'

import Lottie from 'lottie-react'
import { toast } from 'react-toastify';

import moment from 'moment';
import { useProfileData } from '../../hooks/useProfileData';
import { useAuth } from '../../hooks/useAuth';
import { fetchMessagesData, saveMessagesData, updateMessagesData, findAndReplaceLinks } from '../../utils/helper';
import { clearChat, editedMessageResponse, sendMessageToSmartAssistant, getIntroMessage, } from '../../utils/api';
import MessageItem from './messageItem';
import VoiceChat from './voiceChat';
import { MessageDataType, SendMessageRequest, SendMessageResponse, } from './messages.types';
import { UserFormType } from '../creatorOnBoarding/signup.types';
import { USER_TYPE } from '../../config/const';
import UserResponseForm from './userResponseForm';
import ContactUsModal from './contactUsModal';
import SignInSignUpModal from './signSignUpModal';
import SignInSignUpModalForWidget from '../chatWidget/signSignUpModal'
import ShareMessagesSection from './shareMessagesSection';
import CollapseHeaderMessage from '../../component/CollapseHeaderMessage/CollapseHeaderMessage';
import { useSpeechRecognition } from 'react-speech-recognition';
import VoiceChatToggle from '../../component/voiceChatToggle';
import ThumbsUpIcon from '../../svg/ThumbsUpIcon';
import ThumbsDownIcon from '../../svg/ThumbsDownIcon';
import FeedbackMsgIcon from '../../svg/FeedbackMsgIcon';
import TimeoutModal from '../../component/timeoutModal';
import GenericModal from '../../component/GenericModal';
import MicGuy from '../../assets/images/micguy.png';
import { CONTACT_US_MAIL } from '../../config/const';
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import { Citation } from './messages.types';
import { useCreditsContext } from '../navbar';
import SubscribeButton from '../Purchases/subscribeButton';
import AdditionalCreditsButton from '../Purchases/additionalCreditsButton';
import TextChatInput from './textChatInput';
import TypingAnimation from './TypingAnimation';
import CustomTypingIndicator from './CustomTypingIndicator';
import StanfordLogo from '../../assets/images/Stanfordlogo.png'

// const ANONYMOUS_CHAT_LIMIT = 4; //Updating this to 7 as we have a Creator welcome message
const enableDevTools = process.env.REACT_APP_ENABLE_DEVTOOLS === 'true';
const enableVoiceFeature = process.env.REACT_APP_ENABLE_VOICE === 'true';
const enableIntroMessages = process.env.REACT_APP_USE_INTRO_MESSAGES === 'true';
const enableMonetization = process.env.REACT_APP_ENABLE_MONETIZATION === 'true';
const skipTypingAnimation = process.env.REACT_APP_SKIP_TYPING_ANIMATION === 'true';

interface ChatScreenProps {
  isWidget?: boolean,
  handleSignInModal?: boolean,
  setHandleSignInModal?: React.Dispatch<React.SetStateAction<boolean>>,
  setWidgetVoiceMode?: React.Dispatch<React.SetStateAction<boolean>>,
}

const ChatScreen: React.FC<ChatScreenProps> = (props) => {

  const {isWidget = false, handleSignInModal, setHandleSignInModal, setWidgetVoiceMode} = props
  const location = useLocation();
  const [anonymousChatLimit, setAnonymousChatLimit] = useState(4)
  const [clearChatLoading, setClearChatLoading] = useState(false);
  const [useVoiceChat, setUseVoiceChat] = useState(false);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const [genericModalData, setGenericModalData] = useState(null);
  const [errorCode, setErrorCode] = useState<number>();
  const eventTracker = useAnalyticsEventTracker();
  const [citations, setCitations] = useState([]);
  const { credits, setCredits } = useCreditsContext();

  const [loadingAnimation, setLoadingAnimation] = useState(WidgetLoading)

  const handleClearChatClick = async () => {
    try {
      //This will disable the "Clear chat" button
      setClearChatLoading(true);

      const response = await clearChat(returnRoomId());
      eventTracker(`delete-conversation ${returnRoomId()}`);
      let data = await response.json();
      console.log(data?.message)

      //This will re-enable the "Clear chat" button
      setClearChatLoading(false);
      //Refreshing window
      window.location.reload();
    } catch (error) {
      console.error('API call error when deleting chat:', error);
      eventTracker(`delete-conversation failed ${returnRoomId()}`);
      setClearChatLoading(false);
    }
  };
  const [messages, setMessages] = React.useState<any>(null);
  const [isTyping, setIsTyping] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTutorial, setIsTutorial] = React.useState(false);
  const [isDemo, setIsDemo] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);
  const [showActionMenu, setShowActionMenu] = React.useState<HTMLElement>(null);
  const [formData, setFormData] = React.useState<Record<any, any>>(null);
  const [handleModal, setHandleModal] = React.useState<
    'edit' | 'feedback' | null
  >(null);
  const [handleContactModal, setHandleContactModal] =
    React.useState<boolean>(false);
  const [initialMessage, setInitialMessage] = React.useState<boolean>(false);
  const [selectedMessages, setSelectedMessages] = React.useState<
    MessageDataType[]
  >([]);
  const [showSelectMessages, setShowSelectMessages] = React.useState(false);
  const [messageVal, setMessageVal] = useState('');
  const sendDisabled = useRef<boolean>(true);
  const [showTopMessage, setShowTopMessage] = useState(true);
  const [aiTypingMessage, setAiTypingMessage] = useState(null);
  const scrollRef = useRef(null);

  //Verify-Email
  const [showVerifyEmail, setShowVerifyEmail] = React.useState<boolean>(false);
  const inputRef = useRef(null);

  const containerRef = React.useRef(null);

  const navigate = useNavigate();
  const { userName: userNameParam, topic: topicParam } = useParams();
  const userName = userNameParam?.toLowerCase();
  const topic = topicParam?.toLowerCase();

  const initialPrompt = topic
    ? `Start a conversation about ${topic}`
    : 'Give me a 1 sentence summary about who you are and provide 3 topics that I can ask you about. Do not give me a numbered list';

  const {
    authUser,
    authLoading,
    isAnonymousUser,
    setIsAnonymousUser,
    setAnonymousChat,
    resetAnonymousData,
    setResetAnonymousData,
  } = useAuth();
  const {
    // verifyAndLinkCreatorsWithAuthUser,
    fetchProfileDataFromUserName,
    creatorProfileData,
  } = useProfileData();

  const { browserSupportsSpeechRecognition } = useSpeechRecognition();

  React.useEffect(() => {
    // on mount, flip use voice chat to true if necessary
    setUseVoiceChat(location?.state?.useVoiceChat || false);
  }, [location?.state?.useVoiceChat]);

  //verify if the user is logged in and email is not verified then return to verify email
  // or username not exists then redirect to create-profile screen
  React.useEffect(() => {
    if (authLoading) {
      setIsLoading(true);
      return;
    }
    if (
      authUser?.documentId &&
      auth?.currentUser?.emailVerified !== undefined &&
      auth.currentUser.emailVerified === false
    ) {
      if (isWidget) {
        setShowVerifyEmail(true)
        setHandleSignInModal(true)
      }
      else {
        toast.error('Please verify your email to continue');
        navigate('/verify-email');
      }
    } else if (!isWidget && authUser?.documentId && !authUser?.userName) {
      navigate('/create-profile');
    }
  }, [authUser, auth?.currentUser, authLoading]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!authLoading && userName) {
      checkValidFanUserAndFetchCreatorProfileData();
    }
  }, [authUser, userName, authLoading]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (isAnonymousUser && (messages?.length >= (2*anonymousChatLimit+1))) {
      if (isWidget) setHandleSignInModal(true);
      else navigate('/signin')
      setAnonymousChat({
        creatorId: creatorProfileData.documentId,
        messages: messages,
      });
    }
  }, [messages]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // Check if anonymous_chat_limit exists as a key for user database and update the local variable
    if (creatorProfileData && creatorProfileData.anonymous_chat_limit && isWidget) {
      setAnonymousChatLimit(creatorProfileData.anonymous_chat_limit)
    }

    if (
      creatorProfileData?.normalizedUserName === userName &&
      ((authUser?.userType === USER_TYPE.FOLLOWER &&
        userName &&
        creatorProfileData?.documentId) ||
        (authUser?.userType === USER_TYPE.CREATOR &&
          creatorProfileData?.documentId))
    ) {
      fetchNextPage();
    }
  }, [creatorProfileData]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (resetAnonymousData) {
      setHandleSignInModal(false);
      setIsAnonymousUser(false);
      setAnonymousChat(null);
      setResetAnonymousData(false);
    }
  }, [resetAnonymousData]); //eslint-disable-line react-hooks/exhaustive-deps

  //set timeout to fadeout top message box
  React.useEffect(() => {
    if (showTopMessage && !isLoading && creatorProfileData?.documentId) {
      setTimeout(() => {
        setShowTopMessage(false);
      }, 3000);
    }
  }, [isLoading, creatorProfileData?.documentId]); //eslint-disable-line react-hooks/exhaustive-deps

  const checkValidFanUserAndFetchCreatorProfileData = async () => {
    setIsLoading(true);
    await fetchProfileDataFromUserName(userName, true, true, navigate);
    setIsAnonymousUser(false);
    if (!authUser) {
      setIsAnonymousUser(true);
    }

    setIsLoading(false);
  };

  const returnRoomId = () => {
    if (isAnonymousUser) {
      return `${creatorProfileData?.documentId}.anonymous-user`;
    }

    if (
      authUser?.normalizedUserName === userName &&
      authUser?.userType === USER_TYPE.CREATOR
    ) {
      if (topic) return `${authUser?.documentId}.${USER_TYPE.BOT}.${topic}`;
      return `${authUser?.documentId}.${USER_TYPE.BOT}`;
    } else {
      return `${creatorProfileData?.documentId}.${authUser?.documentId}`;
    }
  };

  const fetchNextPage = async (loadingMore = false) => {
    if (isLoading || isLoadingMore) {
      return;
    }

    if (loadingMore) {
      setIsLoadingMore(true);
    } else {
      setIsLoading(true);
    }
    try {
      const querySnapshot = await fetchMessagesData(returnRoomId());

      const nextPageDocuments: DocumentData[] = querySnapshot.docs.map(
        (doc: any) => ({
          id: doc.id,
          documentId: doc.id,
          ...doc.data(),
        })
      );

      setMessages((prevDocuments: any) => [
        ...nextPageDocuments.reverse(),
        // ...(prevDocuments || []),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle the error
    } finally {
      if (loadingMore) {
        setIsLoadingMore(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  React.useEffect(() => {
    if (
      !authLoading &&
      !isLoading &&
      !initialMessage &&
      isAnonymousUser &&
      !authUser
    ) {
      setInitialMessage(true);
      if (!useVoiceChat) {
        handleMessageSend(initialPrompt, true);
      }
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, [isAnonymousUser]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (
      !authLoading &&
      !isLoading &&
      !initialMessage &&
      !isAnonymousUser &&
      messages?.length === 0 &&
      !aiTypingMessage &&
      !isTyping
    ) {
      setInitialMessage(true);
      if (!useVoiceChat) {
        handleMessageSend(initialPrompt, true);
      }
    }
  }, [messages]); //eslint-disable-line react-hooks/exhaustive-deps

  const convertMessageDataType = (
    message: string,
    userType: string,
    userId: string,
    images?: string[],
    image_url?: string,
    image_urls?: string[],
    tempUserId?: number,
    isFinalMessage: boolean = false,
    documentId?: string,
    citations?: Citation[],
  ) => {
    const currentTimeStamp = Timestamp.fromDate(new Date());
    let messageData: MessageDataType = {
      senderId: userId,
      message,
      userType,
      sentTime: currentTimeStamp,
      direction: userType === USER_TYPE.BOT ? 'incoming' : 'outgoing',
      likeDislike: 0,
      roomId: returnRoomId(),
      images: images ?? [],
      image_url: image_url ?? null,
      image_urls: image_urls ?? null,
      tempUserId: tempUserId ?? null,
      citations: citations ?? [],
    };
    if (documentId) {
      messageData.documentId = documentId;
    }
    return messageData;
  };

  React.useEffect(() => {
    inputRef?.current?.focus();
  }, [isTyping]); //eslint-disable-line react-hooks/exhaustive-deps

  const saveMessages = async (
    message: string,
    userType: string,
    userId: string,
    images?: string[],
    image_url?: string,
    image_urls?: string[],
    tempUserId?: number,
    isFinalMessage: boolean = false,
    documentId?: string,
    citations?: Citation[],
  ) => {
    const currentTimeStamp = Timestamp.fromDate(new Date());
    let messageData: MessageDataType = {
      senderId: userId,
      message,
      userType,
      sentTime: currentTimeStamp,
      direction: userType === USER_TYPE.BOT ? 'incoming' : 'outgoing',
      likeDislike: 0,
      roomId: returnRoomId(),
      images: images ?? [],
      image_url: image_url ?? null,
      image_urls: image_urls ?? null,
      tempUserId: tempUserId ?? null,
      citations: citations ?? [],
    };

    if (userType === USER_TYPE.BOT && !isFinalMessage) {
      // AI typing simulation (not the final AI message)
      setMessages(prevMessages => {
        let existingIndex = -1;
        if (prevMessages) {
          existingIndex = prevMessages.findIndex(m => m.tempUserId === tempUserId);
        }
        if (existingIndex !== -1) {
          // Update the existing AI message with new content
          const updatedMessages = [...prevMessages];
          updatedMessages[existingIndex].message = message;
          updatedMessages[existingIndex].documentId = documentId;
          return updatedMessages;
        } else {
          // Add as a new AI message (should rarely happen)
          return (prevMessages ?
            [...prevMessages, { ...messageData, isAiTyping: true }] :
            [{ ...messageData, isAiTyping: true }]);
        }
      });
    } else {
      // Save the message to the database for user messages and final AI message
      const saveMessageResponse = await saveMessagesData({
        ...messageData,
        sentTime: serverTimestamp(),
        isAiTyping: false
      });

      // Update or add the saved message in state
      setMessages(prevMessages => {
        prevMessages ||= [];
        if (tempUserId) {
          // Find and update the existing message with the saved message data
          return prevMessages.map(messageItem =>
            messageItem.tempUserId === tempUserId ? { ...messageItem, ...saveMessageResponse, documentId: saveMessageResponse.documentId, sentTime: messageItem.sentTime } : messageItem
          );
        } else {
          // Add the saved message as a new message
          return [...prevMessages, { ...saveMessageResponse, documentId: saveMessageResponse.documentId }];
        }
      });

      return saveMessageResponse.documentId;
    }
  };

  const isSelfChat = authUser?.documentId === creatorProfileData?.documentId;

  const handleMessageSend = async (
    userMessage: string,
    firstMessage: boolean = false,
    isVoiceMessage: boolean = false,
  ) => {
    setIsTyping(true);
    sendDisabled.current = true;
    const TempUserId = Date.now();  // Unique ID for the user's message
    const aiTempUserId = TempUserId + 1;  // Unique ID for the AI's typing simulation

    try {
      // Save user message
      if (!firstMessage) {
        let userMessageData: MessageDataType = {
          senderId: authUser?.documentId || '',
          message: userMessage,
          userType: authUser?.userType || '',
          sentTime: Timestamp.fromDate(new Date()), // Convert to Firestore Timestamp if needed
          direction: 'outgoing', // Assuming this is for outgoing messages
          likeDislike: 0,
          roomId: returnRoomId(),
          tempUserId: TempUserId
        };

        // Add user message to state
        setMessages(prevMessages => [...prevMessages, userMessageData]);

        // Save user message to database
        saveMessages(
          userMessage,
          authUser?.userType || '',
          authUser?.documentId || '',
          null,
          null,
          null,
          TempUserId,
          true,  // Final message from the user
          null,
          null
        );
      }

      let userData: SendMessageRequest = {
        index: authUser?.documentId,
        query: userMessage,
        room_id: returnRoomId(),
      };
      if (authUser?.normalizedUserName !== userName) {
        userData = {
          index: creatorProfileData?.documentId,
          query: userMessage,
          room_id: returnRoomId(),
        };
      }

      // skip credit consumption for enterprise accounts
      if (isSambaTvUrl || isStanfordUrl || isWidget) {
        userData.skip_credits = true;
      }

      let response;
      if (enableIntroMessages) {
        if (firstMessage && !topic) {
          // eventually replace with just using creatorProfileData.introMessage
          response = await getIntroMessage(creatorProfileData?.documentId, false);
        } else {
          response = await sendMessageToSmartAssistant(userData, authUser?.userType);
        }
      } else {
        response = await sendMessageToSmartAssistant(userData, authUser?.userType);
      }
      if (response?.status === 200) {
        eventTracker('message-send');
        const messageResp: SendMessageResponse = await response.json();
        if (messageResp?.citations && messageResp.citations.length > 0) {
          setCitations(messageResp.citations);
        }
        let fullMessage = findAndReplaceLinks(messageResp?.response);
        let images = messageResp?.images || [];
        let image_url = messageResp?.image_url || null;
        let image_urls = messageResp?.image_urls || null;

        let senderId = creatorProfileData?.documentId;
        let citations = messageResp.citations;

        if (messageResp.decremented_credits){
          setCredits((prev) => prev - messageResp.decremented_credits)
        }

        if (isVoiceMessage || skipTypingAnimation) {
          let documentId = await saveMessages(fullMessage, USER_TYPE.BOT, senderId, images, image_url, image_urls, aiTempUserId, false, null, citations);
          await saveMessages(fullMessage, USER_TYPE.BOT, senderId, images, image_url, image_urls, aiTempUserId, true, documentId, citations);
          setIsTyping(false);
          sendDisabled.current = false;
          return fullMessage;
        }

        // Save the complete AI response before starting typing simulation
        let documentId = await saveMessages(fullMessage, USER_TYPE.BOT, senderId, images, image_url, image_urls, aiTempUserId, true, null, citations);

        setIsTyping(false)
        setAiTypingMessage(convertMessageDataType(fullMessage, USER_TYPE.BOT, senderId, images, image_url, image_urls, aiTempUserId, true, documentId, citations))

      } else {
        setIsTyping(false);
        sendDisabled.current = false;

        setErrorCode(response?.status || 0);
        setShowTimeoutModal(true);
        throw new Error(`HTTP error, status = ${response?.status}`);
      }
    } catch (error) {
      eventTracker('message-send failed');
      console.log(error);
      setIsTyping(false);
      sendDisabled.current = false;
      setErrorCode(0); // set as generic error
      setShowTimeoutModal(true);
    }
  };

  //handle like dislike message, if the new value and existing value are match the field value gets reset (i.e if user has already like a message and again user press like button the like/dislike value gets reset)
  const handleLikeDislikeMessage = async (
    documentId: string,
    value: number
  ) => {
    let existingValue = 0;
    const updatedMsg = messages.map((messageItem) => {
      if (messageItem?.documentId === documentId) {
        existingValue = messageItem.likeDislike;
        if (existingValue === value) {
          messageItem.likeDislike = 0;
          value = 0;
        } else {
          messageItem.likeDislike = value;
        }
      }
      return messageItem;
    });
    setMessages(updatedMsg);
    if (!isAnonymousUser) {
      const updateMessageResp = await updateMessagesData(documentId, {
        likeDislike: value,
      });
      if (!updateMessageResp?.documentId) {
        const updatedMsg = messages.map((messageItem) =>
          messageItem?.documentId === documentId
            ? {
              ...messageItem,
              likeDislike: existingValue,
            }
            : messageItem
        );
        setMessages(updatedMsg);
      }
    }
  };

  const renderUserInfoHeader = (userData: UserFormType, isSambaTvUrl: boolean, isStanfordUrl: boolean) => {
    if (!userData?.userName) {
      return <></>;
    }
    // If on SambaTV URL or Stanford URL, render vertical layout
    if (isSambaTvUrl || isStanfordUrl) {
      return (
        <Box className={isWidget ? 'chat-widget-profile-header' : ''} mt={1} display='flex' flexDirection='column' alignItems='center' sx={{ marginLeft: '20px' }}>
          {userProfileLink(
            <Avatar
              src={userData?.profilePhotoURL}
              alt={userData?.fullName}
              sx={{ m: 1, width: 70, height: 70, fontSize: '1.25rem' }}
            >
              {userData?.fullName ? userData?.fullName[0] : ''}
            </Avatar>
          )}
          {userProfileLink(
            <Typography
              component='h6'
              variant='h5'
              fontWeight={400}
              fontSize={'1.1rem'}
            >
              {userData?.fullName ? (
                <>
                  {userData.fullName.split(' ')[0]}<br />
                  {userData.fullName.split(' ')[1]}
                </>
              ) : ''}
            </Typography>
          )}
          {userProfileLink(
            <Typography
              component='h6'
              variant='body2'
              fontWeight={400}
              color={'#808080'}
            >
              {`@${userData?.userName || ''}`}
            </Typography>
          )}
        </Box>
      );
    } else {
      // If not on SambaTV URL or Stanford URL, render horizontal layout (as is)
      return (
        <Box mt={1} height={isWidget ? '270px' : 'auto'} display='flex' alignItems='center'>
          {isWidget ?
          <Box position='absolute' left='0px' width='100%'>
            <Lottie
              animationData={loadingAnimation}
              loop={true}
              style={{
                width: '100%'
              }}
            />

          </Box>
          : <></>
          }

          {userProfileLink(
            <Avatar
              src={userData?.profilePhotoURL}
              alt={userData?.fullName}
              sx={isWidget ? { m: 1, width: 98, height: 98, fontSize: '3rem', zIndex: 2, boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)'}:{ m: 1, width: 40, height: 40, fontSize: '1.25rem' }}
            >
              {userData?.fullName ? userData?.fullName[0] : ''}
            </Avatar>
          )}
          {isWidget ? <></>
          :
          <Box>
            {userProfileLink(
              <Typography
                component='h6'
                variant='h5'
                fontWeight={400}
                fontSize={'1.1rem'}
              >
                {userData?.fullName || ''}
              </Typography>
            )}
            {userProfileLink(
              <Typography
                component='h6'
                variant='body2'
                fontWeight={400}
                color={'#808080'}
              >
                {`@${userData?.userName || ''}`}
              </Typography>
            )}
          </Box>}
        </Box>
      );
    }
  };


  const handleFormDialogResponse = async (values) => {
    setIsFormSubmitting(true);
    const { documentId } = formData;
    const userResponse = values?.response;
    if (documentId && userResponse) {
      let userFormData: any = {};
      if (handleModal === 'feedback') {
        userFormData.feedback = userResponse;
      } else {
        userFormData.editedMessage = findAndReplaceLinks(userResponse);
        userFormData.question = returnUserQuesForEditedMsg(documentId);
        userFormData.is_indexed = false;
      }
      if (isAnonymousUser) {
        updateExistingMessages(documentId, findAndReplaceLinks(userResponse));
      } else {
        const updateMessageResp = await updateMessagesData(
          documentId,
          userFormData
        );
        if (handleModal === 'edit') {
          try {
            eventTracker('message-edit');
            editedMessageResponse({ message_id: documentId });
          } catch (e) {
            eventTracker('message-edit failed');
            throw e;
          }
        }
        if (updateMessageResp?.documentId) {
          updateExistingMessages(documentId, findAndReplaceLinks(userResponse));
        }
      }

      setIsFormSubmitting(false);
      if (handleModal === 'feedback') {
        toast.success('Feedback submitted');
      }
      handleFormDialogClose();
    }
  };

  const updateExistingMessages = (documentId: string, userResponse: any) => {
    const updatedMsg = messages.map((messageItem) => {
      if (messageItem?.documentId === documentId) {
        if (handleModal === 'feedback') {
          messageItem.feedback = userResponse;
        } else {
          messageItem.editedMessage = userResponse;
        }
      }
      return messageItem;
    });
    setMessages(updatedMsg);
  };

  const returnUserQuesForEditedMsg = (documentId: string) => {
    let question = '';
    const currentMsgInd = messages.findIndex(
      (msgItem) => msgItem.documentId === documentId
    );
    if (currentMsgInd !== -1) {
      for (let i = currentMsgInd - 1; i >= 0; i--) {
        if (messages[i]?.userType === USER_TYPE.CREATOR) {
          question = messages[i].message;
          break;
        }
      }
    }
    return question;
  };

  const handleFormDialogClose = () => {
    setShowActionMenu(null);
    setHandleModal(null);
    setFormData(null);
  };

  const handleContactUsModalClose = () => {
    setHandleContactModal(false);
  };

  const handleShareButtonClick = (selMessage: any) => {
    if (selectedMessages?.length >= 1) {
      const selMsgIndex = selectedMessages?.findIndex(
        (messageItem) => messageItem.documentId === selMessage?.documentId
      );
      if (selMsgIndex !== -1) {
        const updatedMsgs = selectedMessages;
        updatedMsgs.splice(selMsgIndex, 1);
        setSelectedMessages([...updatedMsgs]);
        return;
      }
    }
    setSelectedMessages([...(selectedMessages || []), selMessage]);
  };

  const handleCurrentCheckboxChecked = (messageDocId: string) => {
    return selectedMessages?.some(
      (messageItem) => messageItem.documentId === messageDocId
    );
  };

  const resetMessageSelection = () => {
    setSelectedMessages([]);
    setShowSelectMessages(false);
  };

  //get user data node and return it with user profile link
  const userProfileLink = React.useCallback(
    (children: React.ReactNode) =>
      isLoading || isAnonymousUser ? (
        children
      ) : (
        <Link
          id={`href-user-profile-${creatorProfileData?.userName}`}
          to={`/${creatorProfileData?.userName}`}
          style={{
            cursor: 'pointer',
          }}
        >
          {children}
        </Link>
      ),
    [isLoading, isAnonymousUser, creatorProfileData?.userName]
  );

  const toggleVoiceChat = (newSetting: boolean) => {
    setUseVoiceChat(newSetting);
    if (isWidget) setWidgetVoiceMode(newSetting);
  };

  // Check if the URL starts with 'sambatv.mimio.ai'
  const isSambaTvUrl = window.location.href.startsWith('https://sambatv.mimio.ai') && window.innerWidth >= 968;

  // Check if the URL starts with 'stanford.edu'
  const isStanfordUrl = window.location.href.startsWith('https://stanford.soopra.ai') && window.innerWidth >= 968;

  const [value, setValue] = React.useState('RFIs');

  React.useEffect(() => {
    // Check the current path and update the radio selection accordingly
    if (location.pathname === '/sambatv/chat') {
      setValue('rfis');
    } else if (location.pathname === '/sambatv-mar/chat') {
      setValue('communications');
    } else if (location.pathname === '/ronjon/chat') {
      setValue('Ronjon');
    } else if (location.pathname === '/sortino-chat/chat') {
      setValue('Sortino');
    }
    else if (location.pathname === '/carlos_seligo/chat') {
      setValue('Carlos');
    }
  }, [location.pathname]);


  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Navigate based on the value or update the state of your app
    switch (newValue) {
      case 'ronjon':
        navigate('/ronjon/chat');
        break;
      case 'sortino':
        navigate('/sortino-chat/chat');
        break;
      case 'carlos':
        navigate('/carlos_seligo/chat');
        break;
      case 'rfis':
        navigate('/sambatv/chat');
        break;
      case 'communications':
        navigate('/sambatv-mar/chat');
        break;
      // ... handle other cases
      default:
        break;
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => scrollRef.current?.scrollIntoView({behavior: 'auto'}), 250);
  }

  const tabBackgroundColor = (text) => {
    if ((text.toLowerCase() === 'ronjon' && location.pathname === '/ronjon/chat') ||
      (text.toLowerCase() === 'sortino' && location.pathname === '/sortino-chat/chat') || (text.toLowerCase() === 'carlos' && location.pathname === '/carlos_seligo/chat')  ) {
      return '#8C1515';
    }
    return value === text.toLowerCase() ? '#00B1FF' : 'inherit';
  };

  const CustomCheckedIcon = () => (
    <SvgIcon>
      <circle cx="12" cy="12" r="1" fill="black" />
      <circle cx="12" cy="12" r="8" fill="white" />
      <circle cx="12" cy="12" r="4" fill="black" />
    </SvgIcon>
  );

  const showCheckPermissionsModal = () => {
    let data = {
      title: "Check your permissions",
      message: "We noticed a problem. If your voice is not recording, please make sure you have given microphone permissions.",
      imgSrc: MicGuy,
      buttons: [{
        text: "OK",
        action: () => {setShowGenericModal(false);},
        variant: "contained",
      }, {
        text: "Email Soopra",
        action: () => {
          let link = `mailto:${CONTACT_US_MAIL}&subject=${encodeURIComponent("Voice feature isn't working")}`;
          window.location.href = link;
          setShowGenericModal(false);
        },
        variant: "outlined",
      }]
    };
    setGenericModalData(data);
    setShowGenericModal(true);
  }


  const handleSendMessageButtonClick = (messageVal, sendDisabled, setMessageVal, isTyping, handleMessageSend) => {
    if (isTyping) {
      console.log('AI is typing. Please wait.');
      return;
    }

    const copiedText = window.getSelection()?.toString(); // Get the copied text
    const messageText = messageVal?.trim() || copiedText || ''; // Use copied text if available

    if (messageText) {
      handleMessageSend(messageText);
      sendDisabled.current = true;
      setMessageVal('');
    }
  };

  return (
    <Box className='messages-section'
    sx={isWidget ? {
      height: '100%',
      width: 1,
      background: 'white',
      marginTop: '40px',
      overflow: 'hidden'
    } : {height: '100%', overflow: 'hidden'}}>
      <Grid
        container
        alignItems={'stretch'}
        direction={'row'}
        sx={{ height: '100%' }}
      >

        {/* Creator Info Side */}
        <Grid
          item
          md={isSambaTvUrl || isStanfordUrl  ? 2.55 : 1.55}
          sm={4}
          xs={12}
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '1px',
            backgroundColor: isSambaTvUrl || isStanfordUrl  ? '#F6F6F6' : 'inherit',
          }}
        >
          {(isSambaTvUrl || isStanfordUrl ) && (
            <>
              {!isStanfordUrl && !isWidget && (
                <Box sx={{ position: 'fixed', display: 'flex', justifyContent: 'center', width: '15%' }}>
                  {renderUserInfoHeader(creatorProfileData, isSambaTvUrl, false)}
                </Box>
              )}
              {!isStanfordUrl && (
                <Box sx={{ position: 'fixed', display: 'flex', justifyContent: 'center', width: '15%' }}>
                  {renderUserInfoHeader(creatorProfileData, isSambaTvUrl, isStanfordUrl)}
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: isStanfordUrl ? '0' : '175px',
                }}
              >
                {!isStanfordUrl && (
                  <Typography sx={{}}>
                    __________________
                  </Typography>
                )}


        <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  pt: 4,
                  pb: 4,
                  mr: 2,
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {isStanfordUrl && (
                  <img src={StanfordLogo} alt="Stanford Logo" style={{ paddingRight:'6px' , height: '3.5rem' }} />
                )}
                {isSambaTvUrl ? 'SambaTV Personas' : 'Stanford University'}

            </Typography>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Stanford Personas"
                  sx={{
                    borderRadius: '10px',
                    border: '0px solid grey',
                    width: '90%',
                    '& .Mui-selected': {
                      color: 'white',
                      backgroundColor: isStanfordUrl ? '#8C1515' : '#00B1FF',
                    },
                    '& .MuiTabs-indicator': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  {isSambaTvUrl
                    ? ['RFIs', 'Communications']
                    : [
                        {
                          text: 'Ronjon',
                          profilePic: 'https://firebasestorage.googleapis.com/v0/b/mimio---db.appspot.com/o/images%2FerWA7J0RzebLjC3nddYqMneVGd23%2Fronjon.webp?alt=media&token=6efc192f-750e-4df5-9927-3e5d1c1b0c7b', // Hardcoded profilePic URL for Ronjon
                          fullName: 'Ronjon Nag',
                          userName: 'ronjon',
                        },
                        {
                          text: 'Sortino',
                          profilePic: 'https://firebasestorage.googleapis.com/v0/b/mimio---db.appspot.com/o/images%2FEdPwrOi2oyXchOxXldvc8jY1FBm2%2Fprofile_pic.jpg?alt=media&token=822c8d4a-565e-4a89-bf8f-5e5630385cf5', // Dynamic profilePic URL for Sortino
                          fullName: 'Frank Sortino',
                          userName: 'sortino-chat',
                        },
                        {
                          text: 'Carlos',
                          profilePic: 'https://firebasestorage.googleapis.com/v0/b/mimio---db.appspot.com/o/images%2Fp3Kj2GPP8oQJUzkQeDS9RUEwn1U2%2Fprofile_pic.png?alt=media&token=cb01e471-2515-46e8-9897-8125fb8ababe', // Dynamic profilePic URL for Carlos
                          fullName: 'Carlos Seligo',
                          userName: 'carlos_seligo',
                        },
                      ].map((item, index, arr) => (
                        <Tab
                          key={item.text}
                          label={
                            <Grid container alignItems="center" justifyContent="center" wrap="nowrap" sx={{ width: '100%' }}>
                              <Grid item>
                                <Avatar
                                  src={item.profilePic}
                                  alt={item.fullName}
                                  sx={{ width: 40, height: 40, mr: 1 }}
                                />
                              </Grid>
                              <Grid item sx={{ flexGrow: 1, overflow: 'hidden' }}>
                                <Typography
                                  variant="body1"
                                  noWrap
                                  sx={{
                                    color: tabBackgroundColor(item.text) === '#8C1515' ? 'white' : 'black',
                                    textTransform: 'none'
                                  }}
                                >
                                  {item.fullName}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  noWrap
                                  sx={{
                                    color: tabBackgroundColor(item.text) === '#8C1515' ? 'white' : 'inherit',
                                  }}
                                >
                                  @{item.userName}
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                          value={item.text.toLowerCase()}
                          sx={{
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                            alignItems: 'center',
                            p: 1,
                            minHeight: '48px',
                            backgroundColor: tabBackgroundColor(item.text),
                            borderBottom: index === 0 ? '1px' : 'none',
                            borderRadius: '10px 10px 10px 10px'
                          }}
                        />
                      ))}
                </Tabs>
              </Box>
            </>
          )}

          {!isSambaTvUrl && !isStanfordUrl && !isWidget && enableDevTools && (
            <div
              style={{
                border: '2px solid #000',
                padding: '15px',
                textAlign: 'center',
                marginTop: '60px',
              }}
            >
              <p style={{ fontSize: '16px' }}>
                Dev Tools for dev and testing only
              </p>
              <Button
                id='button-clear-chat'
                onClick={handleClearChatClick}
                disabled={clearChatLoading}
              >
                {clearChatLoading ? 'Deleting...' : 'Clear Chat'}
              </Button>
            </div>
          )}
        </Grid>


        {/* Chat Section */}
        <Grid
          item
          md={9}
          sm={9}
          xs={12}
          ref={containerRef}
          position='relative'
          overflow='hidden'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            maxHeight: isWidget ? '100%' :'calc(100vh - 55px)',
            overflowY: useVoiceChat ? 'auto' : 'hidden'
          }}
        >

          <TimeoutModal
            showTimeoutModal={showTimeoutModal}
            setShowTimeoutModal={setShowTimeoutModal}
            errorCode={errorCode}
          />
          <GenericModal
            showGenericModal={showGenericModal}
            setShowGenericModal={setShowGenericModal}
            data={genericModalData}
          />
          {useVoiceChat ?
            (<VoiceChat
              handleMessageSend={handleMessageSend}
              creatorProfileData={creatorProfileData}
              handleLikeDislikeMessage={handleLikeDislikeMessage}
              messageDataItem={messages ? messages[messages.length - 1] : null}
              toggleVoiceChat={toggleVoiceChat}
              useVoiceChat={useVoiceChat}
              setErrorCode={setErrorCode}
              setShowTimeoutModal={setShowTimeoutModal}
              showCheckPermissionsModal={showCheckPermissionsModal}
              isWidget={isWidget}
            />
            ) : (
              <>
                <div className='messages-container'>
                  <MessageList
                    loading={isLoading}
                    autoScrollToBottom={true} //not changed for widget
                    scrollBehavior='auto' //not changed for widget
                    style={{
                      //height: 'calc(100vh - 60px - 24px - 60px)',
                      height: '100%'
                    }}
                  >
                    <MessageList.Content className={isWidget ? 'chat-widget-content-window' : ''}>
                      {isWidget ?
                      <CollapseHeaderMessage isOpen={showTopMessage}>
                      <Box
                        display='flex'
                        flexDirection='column'
                        textAlign='left'
                        zIndex={1}
                        alignItems='center'
                        width='100%'
                        height='100%'
                        justifyContent='center'
                        bgcolor={'#fff'}
                        sx={{
                          top: 0,
                          overflowY: 'hidden',
                          overflowX: 'hidden',
                          position: showTopMessage ? 'absolute' : 'sticky'
                        }}
                      >
                        {renderUserInfoHeader(creatorProfileData,isSambaTvUrl, false)}

                      </Box>
                      </CollapseHeaderMessage>

                      :
                      <Box
                        display='flex'
                        flexDirection='column'
                        textAlign='left'
                        position='sticky'
                        zIndex={1}
                        alignItems='center'
                        width='100%'
                        height='100%'
                        justifyContent='center'
                        bgcolor={'#fff'}
                        sx={{
                          top: 0,
                          overflowY: 'auto',
                          overflowX: 'hidden'
                        }}
                      >
                        <CollapseHeaderMessage isOpen={showTopMessage}>
                          <Box
                            display='flex'
                            alignItems='center'
                            mt='10px'
                            padding='10px'
                            height='min-content'
                            border='1px solid #000'
                            borderRadius='20px'
                          >
                            <TipsAndUpdatesIcon
                              sx={{
                                height: '40px',
                                width: '40px',
                              }}
                            />
                            <Typography
                              component='h5'
                              variant='body2'
                              fontSize='0.8rem'
                              fontWeight={500}
                              ml={1}
                            >
                              Heads up: This is an AI persona created on Soopra.ai.
                              You can help us by rating responses with{' '}
                              <ThumbsUpIcon
                                height='18px'
                                width='18px'
                                style={{
                                  verticalAlign: 'middle',
                                }}
                              />{' '}
                              or{' '}
                              <ThumbsDownIcon
                                height='18px'
                                width='18px'
                                style={{
                                  verticalAlign: 'middle',
                                }}
                              />{' '}
                              or click on{' '}
                              <FeedbackMsgIcon
                                height='18px'
                                width='18px'
                                style={{
                                  verticalAlign: 'middle',
                                }}
                              />{' '}
                              to leave more feedback. Your input accelerates the
                              progress!
                            </Typography>
                          </Box>
                        </CollapseHeaderMessage>

                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                          width='100%'
                          position='relative'
                          zIndex={1}
                          sx={{
                            top: 0,
                          }}
                        >
                          {!isSambaTvUrl && !isStanfordUrl && renderUserInfoHeader(creatorProfileData, isSambaTvUrl, isStanfordUrl)}
                          {!!creatorProfileData && (
                            <Divider
                              sx={{
                                borderColor: '#ccc',
                                position: 'absolute',
                                bottom: 0,
                                left: '-0.8em',
                                right: '-1.2em',
                              }}
                            />
                          )}
                        </Box>
                      </Box>}
                        {showTopMessage ? <></>:
                        <Box>
                          {(!messages || messages?.length <= 0) && !aiTypingMessage && creatorProfileData ? (
                            isWidget ?
                              <></> :
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 1,
                                // height:
                                //   messages?.length >= 1
                                //     ? 'calc(100vh - 230px)'
                                //     : 'calc(100vh - 380px)',
                              }}
                            >
                              <Avatar
                                alt={creatorProfileData?.fullName}
                                src={creatorProfileData?.profilePhotoURL}
                                sx={{
                                  m: 1,
                                  width: 100,
                                  height: 100,
                                  marginBottom: '1rem',
                                  fontSize: '3.125rem'
                                }}
                              >{creatorProfileData?.fullName ? creatorProfileData?.fullName[0] : ''}</Avatar>
                              <Typography
                                fontSize={16}
                                fontWeight={400}
                              >{`Start chat with ${creatorProfileData?.fullName}`}</Typography>
                            </Box>
                          ) : (
                            messages?.length >= 1 &&
                            messages?.map(
                              (
                                messageDataItem: MessageDataType,
                                messageIndex: number
                              ) => (
                                <Box key={messageIndex}>
                                  {(messageIndex === 0 ||
                                    moment(
                                      typeof messages[messageIndex - 1]?.sentTime ===
                                        'object'
                                        ? messages[
                                          messageIndex - 1
                                        ]?.sentTime?.toDate?.()
                                        : messages[messageIndex - 1]?.sentTime
                                    ).format('DD-MM-YYYY') !==
                                    moment(
                                      typeof messageDataItem?.sentTime === 'object'
                                        ? messageDataItem?.sentTime?.toDate?.()
                                        : messageDataItem?.sentTime
                                    ).format('DD-MM-YYYY')) && (
                                      <MessageSeparator
                                        content={moment(
                                          typeof messageDataItem?.sentTime === 'object'
                                            ? messageDataItem?.sentTime?.toDate?.()
                                            : messageDataItem?.sentTime
                                        ).format('DD MMMM YYYY')}
                                    style={{ overflowY: 'auto' }}
                                      />
                                    )}
                                  <Box display='flex'>
                                    {showSelectMessages && (
                                      <Checkbox
                                        onChange={(event) =>
                                          handleShareButtonClick(messageDataItem)
                                        }
                                        checked={handleCurrentCheckboxChecked(
                                          messageDataItem?.documentId
                                        )}
                                        sx={{
                                          '&.MuiButtonBase-root.MuiCheckbox-root': {
                                            height: 'fit-content',
                                            alignSelf: 'center',
                                          },
                                        }}
                                        icon={<RadioButtonUncheckedIcon />}
                                        checkedIcon={
                                          <RadioButtonCheckedIcon
                                            sx={{ color: '#3DA7E7' }}
                                          />
                                        }
                                      />
                                    )}
                                    <MessageItem
                                      isSelfChat={isSelfChat}
                                      messageDataItem={messageDataItem}
                                      citations={citations.filter(citation => citation.messageId === messageDataItem.documentId)}
                                      creatorUserData={creatorProfileData}
                                      authUserData={authUser}
                                      handleLikeDislikeMessage={
                                        handleLikeDislikeMessage
                                      }
                                      indexKey={`message-${messageIndex}`}
                                      showActionMenu={showActionMenu}
                                      setShowActionMenu={setShowActionMenu}
                                      handleModal={handleModal}
                                      setHandleModal={setHandleModal}
                                      formData={formData}
                                      setFormData={setFormData}
                                      userName={userName}
                                      handleShareButtonClick={handleShareButtonClick}
                                      showSelectMessages={showSelectMessages}
                                      setShowSelectMessages={setShowSelectMessages}
                                      resetMessageSelection={resetMessageSelection}
                                      scrollToBottom={scrollToBottom}
                                      isWidget={isWidget}
                                    />
                                  </Box>
                                </Box>
                              )
                            )
                          )}

                      {isWidget? <></>:<CustomTypingIndicator isTyping={isTyping} avatarImage={`${creatorProfileData?.profilePhotoURL}`} fullName={`${creatorProfileData?.fullName}`}/>}

                      {/* AI Typing animation */}
                      {aiTypingMessage &&
                        <TypingAnimation
                          isSelfChat={isSelfChat}
                          messageDataItem={aiTypingMessage}
                          citations={citations.filter(citation => citation.messageId === aiTypingMessage.documentId)}
                          creatorUserData={creatorProfileData}
                          authUserData={authUser}
                          handleLikeDislikeMessage={
                            handleLikeDislikeMessage
                          }
                          indexKey={`message-typing`}
                          showActionMenu={showActionMenu}
                          setShowActionMenu={setShowActionMenu}
                          handleModal={handleModal}
                          setHandleModal={setHandleModal}
                          formData={formData}
                          setFormData={setFormData}
                          userName={userName}
                          handleShareButtonClick={handleShareButtonClick}
                          showSelectMessages={showSelectMessages}
                          setShowSelectMessages={setShowSelectMessages}
                          resetMessageSelection={resetMessageSelection}

                          setAiTypingMessage={setAiTypingMessage}
                          setMessages={setMessages}
                          sendDisabled={sendDisabled}
                          isWidget={isWidget}
                        />

                      }
                      </Box>
                      }
                      {
                        enableMonetization && !isSambaTvUrl && !isStanfordUrl &&
                        !isWidget && !isAnonymousUser &&
                        !isSelfChat && !isLoading && (credits === 0) &&
                        (
                          authUser.isSubscribed ?
                            <AdditionalCreditsButton redirectURL={`/${userName}/chat`}/> :
                            <SubscribeButton redirectURL={`/${userName}/chat`}/>
                        )
                      }

                      <div ref={scrollRef}></div>
                    </MessageList.Content>
                  </MessageList>

                </div>

                {showSelectMessages && (
                  <ShareMessagesSection
                    containerRef={containerRef}
                    selectedMessages={selectedMessages}
                    resetMessageSelection={resetMessageSelection}
                    creatorProfileData={creatorProfileData}
                  />
                )}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column', // Align children vertically
                    justifyContent: 'flex-end', // Push content to the bottom
                    width: '100%',
                  }}
                >
                  {isWidget ? <CustomTypingIndicator isTyping={isTyping} avatarImage={`${creatorProfileData?.profilePhotoURL}`} fullName={`${creatorProfileData?.fullName}`} isWidget/> : <></>}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      background: isWidget ? 'white': '',
                    }}

                    className={isWidget ?'text-input-chat-widget':''}
                  >
                    {enableVoiceFeature &&
                      creatorProfileData?.voiceId &&
                      browserSupportsSpeechRecognition && (
                        <VoiceChatToggle
                          toggleVoiceChat={toggleVoiceChat}
                          useVoiceChat={useVoiceChat}
                        />
                      )}

                    {isWidget ?
                    <>
                      <MessageInput
                      id='text-input-chat-widget-message'
                      sendDisabled={sendDisabled.current}
                      sendButton={false}
                      ref={inputRef}
                      value={messageVal}
                      disabled={enableMonetization && !isSambaTvUrl && !isStanfordUrl && !isWidget && !isAnonymousUser && !isSelfChat && !isLoading && (credits === 0)}
                      onChange={(val) => {
                        sendDisabled.current = val.length === 0;
                        setMessageVal(val)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && sendDisabled.current && !e.shiftKey) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                        if (e.clipboardData) {
                          let content = e.clipboardData.getData('text/plain');
                          sendDisabled.current = (messageVal + content).length === 0;

                          setMessageVal(messageVal + content);
                        }
                      }}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0.6rem',
                        width: '60px',
                        flexGrow: '1',
                      }}
                      placeholder={`Ask ${creatorProfileData?.fullName}'s AI persona anything...`}
                      attachButton={false}
                      onSend={(innerHtml: string, textContent: string) => {handleSendMessageButtonClick(textContent, sendDisabled, setMessageVal, isTyping, handleMessageSend)}}
                    />
                      <button className={`cs-button--arrow ${messageVal ? `cs-button--focussed` : ''}`} onClick={() => handleSendMessageButtonClick(messageVal, sendDisabled, setMessageVal, isTyping, handleMessageSend)}>
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.159096 3.95813L3.61591 0.174153C3.82804 -0.05805 4.17196 -0.05805 4.38409 0.174154L7.8409 3.95813C8.05303 4.19033 8.05303 4.56681 7.8409 4.79901C7.62878 5.03122 7.28485 5.03122 7.07272 4.79901L4.54319 2.03007L4.54319 9.45681C4.54319 9.75681 4.29999 10 4 10C3.70001 10 3.45681 9.75681 3.45681 9.45681L3.45681 2.03007L0.927276 4.79901C0.715149 5.03122 0.371223 5.03122 0.159096 4.79901C-0.0530316 4.56681 -0.0530316 4.19033 0.159096 3.95813Z" fill="white"/>
                        </svg>
                      </button>
                      </>
                    :
                    <TextChatInput
                      sendDisabled={sendDisabled}
                      disabled={enableMonetization && !isSambaTvUrl && !isStanfordUrl && !isWidget && !isAnonymousUser && !isSelfChat && !isLoading && (credits === 0)}
                      isTyping={isTyping}
                      handleMessageSend={handleMessageSend}
                    />}
                  </Box>
                </Box>

              </>
            )}
        </Grid>
      </Grid>
{/*
      {isDemo && (
        <FanDemo
          onClose={() => {
            setIsTutorial(true);
          }}
        />
      )} */}
      <UserResponseForm
        handleModal={handleModal}
        formData={formData}
        handleFormDialogClose={handleFormDialogClose}
        handleFormDialogResponse={handleFormDialogResponse}
        isFormSubmitting={isFormSubmitting}
      />
      <ContactUsModal
        handleContactModal={handleContactModal}
        handleContactUsModalClose={handleContactUsModalClose}
      />
      {isWidget ?
        <SignInSignUpModalForWidget handleSignInModal={handleSignInModal} setHandleSignInModal={setHandleSignInModal} showVerifyEmail={showVerifyEmail} setShowVerifyEmail={setShowVerifyEmail}/>
        :
        <SignInSignUpModal handleSignInModal={handleSignInModal} setHandleSignInModal={setHandleSignInModal} />
      }
        </Box>
  );
};

export default ChatScreen;
